/**
 * 数据格式化相关方法
 */
import moment from 'moment';

export function statusFormat(row) {
	return statusFormatByStatus(row.status);
}

export function statusFormatByStatus(status) {
	if (status === 8) {
		return '待派单';
	} else if (status === 1) {
		return '待首联';
	} else if (status === 2) {
		return '待安装';
	} else if (status === 3) {
		return '待审核';
	} else if (status === 4) {
		return '被驳回';
	} else if (status === 5) {
		return '暂不安装';
	} else if (status === 6) {
		return '待结算';
	} else if (status === 7) {
		return '已结算';
	} else if (status === 9) {
		return '电表申请中';
	} else if (status === 10) {
		return '等客户通知';
	} else if (status === 11) {
		return '取消待审核';
	} else if (status === 12) {
		return '已取消';
	} else {
		return '-';
	}
}
export function orderTypeFormat(row) {
	if (row.orderType === 1) {
		return '新订单';
	} else if (row.orderType === 2) {
		return '维修单';
	} else if (row.orderType === 3) {
		return '自提单';
	}
}
export function repairTypeFormat(row) {
	if (row.repairType === 1) {
		return '更换充电桩';
	} else if (row.repairType === 2) {
		return '更换漏保';
	} else if (row.repairType === 3) {
		return '地线维修';
	} else if (row.repairType === 4) {
		return '其他维修';
	}
}
export function houseTypeFormat(row) {
	if (row.houseType === 0) {
		return '农村自建房';
	} else if (row.houseType === 1) {
		return '城市小区';
	} else if (row.houseType === 2) {
		return '城市自建房';
	} else if (row.houseType === 3) {
		return '工厂';
	} else if (row.houseType === 4) {
		return '其他';
	}
}
export function dateFormat(date) {
	if (date == null || date == undefined || date == '') {
		return '';
	}

	return moment(date).format('YYYY-MM-DD');
}

export function dateTimeFormat(date) {
	if (date == null || date == undefined || date == '') {
		return '';
	}

	return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function transformUrl(url) {
	if (url == null || url == undefined || url == '') {
		return '';
	}

	return '/api/v1/web/order-info/preview-image?path=' + url;
}
