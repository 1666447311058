<template>
	<div style="height: 100%">
		<div style="padding: 20px; height: 800px">
			<!-- 搜素框 -->
			<el-form
				:inline="true"
				:model="params"
				style="
					padding-top: 20px;
					padding-left: 20px;
					margin-bottom: 20px;
					background-color: #fff;
					border-radius: 8px;
				"
				size="small"
			>
				<el-form-item label="模板名称">
					<el-input v-model="params.templateName" placeholder="模板名称"></el-input>
				</el-form-item>
				<el-form-item label="公司">
					<el-select filterable v-model="params.companyId" placeholder="请选择">
						<el-option
							v-for="item in companyList"
							:key="item.companyId"
							:label="item.companyManager"
							:value="item.companyId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属充电桩类型">
					<el-select filterable v-model="params.deviceCategoryId" placeholder="请选择">
						<el-option
							v-for="item in categoryList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button @click="checkData" type="primary" size="mini">查询</el-button>
					<el-button type="info" @click="reset" size="mini">重置</el-button>
				</el-form-item>
			</el-form>
			<div style="height: 82%; background-color: #fff; border-radius: 8px; padding-top: 8px">
				<el-button
					type="primary"
					size="small"
					style="float: right; margin-top: 20px; margin-right: 20px; margin-bottom: 20px"
					@click="add()"
					v-show="authorization('/home-admin/device/device-image-setting/add')"
					>新增</el-button
				>
				<el-button
					type="success"
					size="small"
					style="float: right; margin-top: 20px; margin-right: 20px; margin-bottom: 20px"
					@click="copy()"
					v-show="authorization('/home-admin/device/device-image-setting/copy')"
					>复制</el-button
				>
				<el-table
					:data="tableData"
					stripe
					style="padding: 0 20px"
					height="80%"
					size="small"
					:header-cell-style="{
						'background-color': '#84aace4a',
						color: '#343333',
						'text-align': 'center',
					}"
					:cell-style="{ 'text-align': 'center' }"
					@selection-change="handleSelectionChange"
				>
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="templateName" label="模板名称" width="150"></el-table-column>
					<el-table-column prop="companyName" label="公司名称" width="120"></el-table-column>
					<el-table-column
						prop="orderType"
						label="订单类型"
						width="100"
						:formatter="orderTypeFormat"
					></el-table-column>
					<el-table-column
						prop="repairType"
						label="维修类型"
						width="100"
						:formatter="repairTypeFormat"
					></el-table-column>
					<el-table-column
						prop="categoryName"
						label="所属充电桩类型"
						:formatter="categoryNameFormat"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column
						prop="createTime"
						label="创建时间"
						:formatter="dateFormat"
						width="150"
					></el-table-column>
					<el-table-column
						prop="createTime"
						label="修改时间"
						:formatter="dateFormat"
						width="150"
					></el-table-column>
					<el-table-column prop="orderIndex" label="排序" width="60"></el-table-column>
					<el-table-column label="操作" width="160">
						<template slot-scope="scope">
							<el-tooltip
								effect="dark"
								content="编辑"
								placement="top-end"
								v-show="authorization('/home-admin/device/device-image-setting/edit')"
							>
								<el-button
									type="primary"
									icon="el-icon-edit"
									circle
									size="mini"
									@click="edit(scope.row)"
									style="margin-right: 5px"
								></el-button>
							</el-tooltip>
							<el-tooltip
								effect="dark"
								content="删除"
								placement="top-end"
								v-show="authorization('/home-admin/device/device-image-setting/del')"
							>
								<el-popconfirm title="确认删除吗？" @confirm="deleteImageSetting(scope.row)">
									<el-button
										content="删除"
										slot="reference"
										type="danger"
										icon="el-icon-delete"
										circle
										size="mini"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div
					style="
						padding-top: 20px;
						height: 40px;
						background-color: #fff;
						width: 100%;
						border-radius: 8px;
						text-align: right;
					"
				>
					<el-pagination
						background
						:page-sizes="sizes"
						layout="total,sizes,prev,pager,next"
						:total="total"
						@current-change="handleCurrentChange"
						@size-change="sizeChange"
					></el-pagination>
				</div>
			</div>
		</div>

		<el-dialog
			title="新增"
			:visible.sync="dialogFormAdd"
			width="800px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="imageSettingParams"
				:rules="rules"
				ref="imageSettingParams"
				label-width="160px"
				size="small"
				style="width: 700px"
			>
				<el-form-item label="模板名称" prop="templateName">
					<el-input v-model="imageSettingParams.templateName"></el-input>
				</el-form-item>
				<el-form-item label="公司" prop="companyId">
					<el-select
						filterable
						v-model="imageSettingParams.companyId"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option
							v-for="item in companyList"
							:key="item.companyId"
							:label="item.companyManager"
							:value="item.companyId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型" prop="orderType">
					<el-select
						v-model="imageSettingParams.orderType"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option label="新订单" :value="1"></el-option>
						<el-option label="维修单" :value="2"></el-option>
						<el-option label="自提单" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="维修类型"
					prop="repairType"
					v-show="imageSettingParams.orderType === 2"
					:rules="imageSettingParams.orderType === 2 ? rules.repairType : [{ required: false }]"
				>
					<el-select
						v-model="imageSettingParams.repairType"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option label="更换充电桩" :value="1"></el-option>
						<el-option label="更换漏保" :value="2"></el-option>
						<el-option label="地线维修" :value="3"></el-option>
						<el-option label="其他维修" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="所属充电桩类型"
					prop="deviceCategoryIdList"
					label-width="160px"
					size="small"
				>
					<el-select
						filterable
						multiple
						v-model="imageSettingParams.deviceCategoryIdList"
						placeholder="请选择所属充电桩类型"
						style="width: 100%"
					>
						<el-option
							v-for="item in categoryList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div
				style="
					width: 660px;
					height: auto;
					margin: auto;
					overflow: auto;
					background-color: #fff;
					border-radius: 8px;
					margin-top: 5px;
				"
			>
				<div style="width: 100%; height: 50px; line-height: 50px">
					<strong>模板详情</strong>
					<el-button
						style="float: right; margin-top: 5px"
						type="primary"
						size="mini"
						@click="addTemplate"
						>添加</el-button
					>
				</div>
				<el-table
					size="mini"
					:data="templateData"
					stripe
					:header-cell-style="{ background: 'rgba(132,170,206,0.29)', color: '#343333' }"
					height="260px"
					width="100%"
				>
					<el-table-column prop="imageName" label="图片名称" align="center">
						<template slot-scope="scope">
							<el-input
								v-if="isEdit[scope.$index]"
								type="text"
								size="mini"
								clearable
								v-model.trim="scope.row.imageName"
							/>
							<span v-if="!isEdit[scope.$index]">{{ scope.row.imageName }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="isRequired" label="是否必填" width="100px;" align="center">
						<template slot-scope="scope">
							<el-select
								v-if="isEdit[scope.$index]"
								v-model.trim="scope.row.isRequired"
								placeholder="请选择是否必填"
								style="width: 100%"
								size="mini"
							>
								<el-option label="是" :value="1"></el-option>
								<el-option label="否" :value="0"></el-option>
							</el-select>
							<span v-if="!isEdit[scope.$index]">{{ scope.row.isRequired ? '是' : '否' }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="orderIndex" label="排序" width="120px" align="center">
						<template slot-scope="scope">
							<el-input-number
								v-if="isEdit[scope.$index]"
								v-model.trim="scope.row.orderIndex"
								:min="0"
								size="mini"
								style="width: 100%"
							></el-input-number>
							<span v-if="!isEdit[scope.$index]">{{ scope.row.orderIndex }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="100px" align="center">
						<template slot-scope="scope">
							<el-tooltip
								effect="dark"
								content="完成"
								placement="top-end"
								v-if="isEdit[scope.$index]"
							>
								<el-button
									type="success"
									icon="el-icon-check"
									circle
									size="mini"
									@click="checkTemplate(scope)"
									style="margin-left: 10px"
								></el-button>
							</el-tooltip>
							<el-tooltip
								effect="dark"
								content="编辑"
								placement="top-end"
								v-if="!isEdit[scope.$index]"
							>
								<el-button
									type="primary"
									icon="el-icon-edit"
									circle
									size="mini"
									@click="editTemplate(scope.$index)"
									style="margin-left: 10px"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="删除" placement="top-end">
								<el-popconfirm title="确认删除吗？" @confirm="delTemplate(scope)">
									<el-button
										content="删除"
										slot="reference"
										type="danger"
										icon="el-icon-delete"
										circle
										size="mini"
										style="margin-left: 10px"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" style="margin-top: 10px">
				<el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="addSubMit('imageSettingParams')" size="mini"
					>确 定</el-button
				>
			</div>
		</el-dialog>

		<el-dialog
			title="编辑"
			:visible.sync="dialogFormEdit"
			width="800px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="imageSettingParams"
				:rules="rules"
				ref="imageSettingParams"
				label-width="160px"
				size="small"
				style="width: 700px"
			>
				<el-form-item label="模板名称" prop="templateName">
					<el-input v-model="imageSettingParams.templateName"></el-input>
				</el-form-item>
				<el-form-item label="公司" prop="companyId">
					<el-select
						filterable
						v-model="imageSettingParams.companyId"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option
							v-for="item in companyList"
							:key="item.companyId"
							:label="item.companyManager"
							:value="item.companyId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型" prop="orderType">
					<el-select
						v-model="imageSettingParams.orderType"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option label="新订单" :value="1"></el-option>
						<el-option label="维修单" :value="2"></el-option>
						<el-option label="自提单" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="维修类型"
					prop="repairType"
					v-show="imageSettingParams.orderType === 2"
					:rules="imageSettingParams.orderType === 2 ? rules.repairType : [{ required: false }]"
				>
					<el-select
						v-model="imageSettingParams.repairType"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option label="更换充电桩" :value="1"></el-option>
						<el-option label="更换漏保" :value="2"></el-option>
						<el-option label="地线维修" :value="3"></el-option>
						<el-option label="其他维修" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="所属充电桩类型"
					prop="deviceCategoryIdList"
					label-width="160px"
					size="small"
				>
					<el-select
						filterable
						multiple
						v-model="imageSettingParams.deviceCategoryIdList"
						@change="deviceCategoryChange"
						placeholder="请选择所属充电桩类型"
						style="width: 100%"
					>
						<el-option
							v-for="item in categoryList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div
				style="
					width: 660px;
					height: auto;
					margin: auto;
					overflow: auto;
					background-color: #fff;
					border-radius: 8px;
					margin-top: 5px;
				"
			>
				<div style="width: 100%; height: 50px; line-height: 50px">
					<strong>模板详情</strong>
					<el-button
						style="float: right; margin-top: 5px"
						type="primary"
						size="mini"
						@click="addTemplate"
						>添加</el-button
					>
				</div>
				<el-table
					size="mini"
					:data="templateData"
					stripe
					:header-cell-style="{ background: 'rgba(132,170,206,0.29)', color: '#343333' }"
					height="260px"
					width="100%"
				>
					<el-table-column prop="imageName" label="图片名称" align="center">
						<template slot-scope="scope">
							<el-input
								v-if="isEdit[scope.$index]"
								type="text"
								size="mini"
								clearable
								v-model.trim="scope.row.imageName"
							/>
							<span v-if="!isEdit[scope.$index]">{{ scope.row.imageName }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="isRequired" label="是否必填" width="100px;" align="center">
						<template slot-scope="scope">
							<el-select
								v-if="isEdit[scope.$index]"
								v-model.trim="scope.row.isRequired"
								placeholder="请选择是否必填"
								style="width: 100%"
								size="mini"
							>
								<el-option label="是" :value="1"></el-option>
								<el-option label="否" :value="0"></el-option>
							</el-select>
							<span v-if="!isEdit[scope.$index]">{{ scope.row.isRequired ? '是' : '否' }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="orderIndex" label="排序" width="120px" align="center">
						<template slot-scope="scope">
							<el-input-number
								v-if="isEdit[scope.$index]"
								v-model.trim="scope.row.orderIndex"
								:min="0"
								size="mini"
								style="width: 100%"
							></el-input-number>
							<span v-if="!isEdit[scope.$index]">{{ scope.row.orderIndex }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="100px" align="center">
						<template slot-scope="scope">
							<el-tooltip
								effect="dark"
								content="完成"
								placement="top-end"
								v-if="isEdit[scope.$index]"
							>
								<el-button
									type="success"
									icon="el-icon-check"
									circle
									size="mini"
									@click="checkTemplate(scope)"
									style="margin-left: 10px"
								></el-button>
							</el-tooltip>
							<el-tooltip
								effect="dark"
								content="编辑"
								placement="top-end"
								v-if="!isEdit[scope.$index]"
							>
								<el-button
									type="primary"
									icon="el-icon-edit"
									circle
									size="mini"
									@click="editTemplate(scope.$index)"
									style="margin-left: 10px"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="删除" placement="top-end">
								<el-popconfirm title="确认删除吗？" @confirm="delTemplate(scope)">
									<el-button
										content="删除"
										slot="reference"
										type="danger"
										icon="el-icon-delete"
										circle
										size="mini"
										style="margin-left: 10px"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" style="margin-top: 10px">
				<el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="editSubMit('imageSettingParams')" size="mini"
					>确 定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import moment from 'moment';
	import { authorization } from '../../../authorization/authorization';
  import { orderTypeFormat, repairTypeFormat} from '../common/format';
	export default {
		data() {
			return {
				multipleSelection: [],
				categoryList: [],
				companyList: [],
				isEdit: [],
				dialogFormAdd: false,
				dialogFormEdit: false,
				tableData: [],
				templateData: [],
				params: {
					page: 1,
					size: 10,
				},
				imageSettingParams: {
					templateName: '',
					deviceCategoryIdList: [],
					templateDetailList: [],
					companyId: [],
				},
				rules: {
					templateName: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
					companyId: [{ required: true, message: '请选择公司', trigger: 'change' }],
					deviceCategoryIdList: [
						{ required: true, message: '请选择归属充电桩类型', trigger: 'change' },
					],
					orderType: [{ required: true, message: '请选择订单类型', trigger: 'change' }],
					repairType: [{ required: true, message: '请选择维修类型', trigger: 'change' }],
				},
				total: 0,
				sizes: [10, 15],
			};
		},
		created() {
			this.queryDeviceCategory();
			this.getCompanyDrown();
			this.queryTableData();
		},
		methods: {
			authorization,
			orderTypeFormat,
			repairTypeFormat,
			addTemplate() {
				let obj = {};
				obj.imageName = '';
				obj.isRequired = 1;
				obj.orderIndex = '';
				this.templateData.push(obj);
				this.isEdit.push(true);
			},
			checkTemplate(scope) {
				let index = scope.$index;
				if (scope.row.imageName?.length <= 0) {
					this.$message.error('图片名称不能为空');
					return;
				} else {
					for (let i = 0; i < this.templateData.length; i++) {
						if (i === index) {
							continue;
						}

						if (this.templateData[i].imageName == scope.row.imageName) {
							this.$message.error('图片名称不能重复');
							return;
						}
					}
				}

				this.$set(this.isEdit, index, false);
			},
			delTemplate(scope) {
				this.templateData.splice(scope.$index, 1);
			},
			editTemplate(index) {
				this.$set(this.isEdit, index, true);
			},
			checkData() {
				this.queryTableData();
			},
			reset() {
				this.params = { page: 1, size: 10, userId: null };
				this.queryTableData();
			},
			deviceCategoryChange(e) {
				this.$set(this.imageSettingParams, this.imageSettingParams.deviceCategoryIdList, e);
			},
			handleCurrentChange(val) {
				this.params.page = val;
				this.queryTableData();
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.queryTableData();
			},
			queryDeviceCategory() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-category',
				}).then((res) => {
					this.categoryList = res.data.data.list;
				});
			},
			getCompanyDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/company-manager/select-company-drown',
				}).then((res) => {
					this.companyList = res.data.data;
				});
			},
			add() {
				//新增弹出层
				this.dialogFormAdd = true;
				this.templateData = [];
				this.isEdit = [];
				this.imageSettingParams = {
					templateName: '',
					deviceCategoryIdList: [],
					templateDetailList: [],
				};
			},
			addSubMit(formName) {
				//新增提交
				if (this.templateData.length <= 0) {
					this.$message.error('模板没有任何数据');
					return;
				}

				this.imageSettingParams.templateDetailList = this.templateData;

				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/device-image-setting/add-image-setting',
							data: this.imageSettingParams,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormAdd = false;
								this.$message({ message: '添加成功', type: 'success' });
								this.queryTableData();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			edit(row) {
				//修改弹出层
				this.dialogFormEdit = true;
				this.imageSettingParams = { ...row };
				this.imageSettingParams.deviceCategoryIdList = [];
				for (let i = 0; i < row.deviceCategoryList.length; i++) {
					this.imageSettingParams.deviceCategoryIdList.push(row.deviceCategoryList[i].id);
				}

				this.templateData = [];
				this.isEdit = [];
				for (let i = 0; i < row.templateDetailList.length; i++) {
					this.templateData.push(row.templateDetailList[i]);
					this.isEdit.push(false);
				}
			},
			editSubMit(formName) {
				//修改提交
				if (this.templateData.length <= 0) {
					this.$message.error('模板没有任何数据');
					return;
				}

				this.imageSettingParams.templateDetailList = this.templateData;
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url:
								'/v1/web/device-image-setting/update-image-setting/' + this.imageSettingParams.id,
							data: this.imageSettingParams,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormEdit = false;
								this.$message({ message: '修改成功', type: 'success' });
								this.queryTableData();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			deleteImageSetting(row) {
				this.axios({
					method: 'delete',
					url: '/v1/web/device-image-setting/delete-image-setting/' + row.id,
					params: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.queryTableData();
					} else {
						this.$message({ type: 'error', message: res.data.message });
					}
				});
			},
			copy() {
				if (this.multipleSelection.length !== 1) {
					this.$message({ message: '请勾选且仅能勾选一个待复制的模板', type: 'error' });
					return;
				}

				var templateId = this.multipleSelection[0].id;
				this.axios({
					method: 'post',
					url: '/v1/web/device-image-setting/copy-image-setting',
					data: { templateId: templateId },
				}).then((res) => {
					if (res.data.code === 200) {
						this.$message({ message: '复制成功', type: 'success' });
						this.queryTableData();
					} else {
						this.$message.error(res.data.message);
					}
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			queryTableData() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-image-setting/select-image-setting',
					params: this.params,
				}).then((res) => {
					this.tableData = res.data.data.list;
					this.total = res.data.data.total;
				});
			},
			dateFormat: function (row) {
				if (row.createTime !== null && row.createTime !== undefined) {
					return moment(row.createTime).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			categoryNameFormat: function (row) {
				if (row.deviceCategoryList.length > 0) {
					let name = '';
					for (let i = 0; i < row.deviceCategoryList.length; i++) {
						if (i > 0) {
							name = name + ',';
						}
						name = name + row.deviceCategoryList[i].categoryName;
					}
					return name;
				}
				return '';
			},
		},
	};
</script>

<style scoped></style>
